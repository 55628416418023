* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', SimSun, sans-serif;
}

:root {
  --foreground: #05020e;
  --background: #fff;
  --light: 212, 89%, 57%;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground: #fff;
    --background: #05020e;
    --light: 212, 89%, 57%;
  }
}

body {
  color: rgb(var(--foreground));
  background-color: rgb(var(--background));
}

[data-slot='input-wrapper'] {
  height: auto !important;
}

#style-preset {
  cursor: pointer !important;
}

input[type='file' i] {
  appearance: none;
  background-color: initial;
  cursor: default;
  align-items: baseline;
  color: inherit;
  text-overflow: ellipsis;
  text-align: start !important;
  padding: initial;
  border: initial;
  white-space: pre;
  overflow: hidden !important;
}

.input {
  outline: none !important;
}

html {
  font-size: 16px;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

button:focus {
  outline: none !important;
}

*::before,
::after {
  box-sizing: border-box;
}

.line-clamp {
  overflow: hidden;
  /* stylelint-disable-next-line */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

svg {
  vertical-align: baseline !important;
}

.animation {
  height: 100vh;
  color: #fff;
  background: linear-gradient(-45deg, #8a8a8a, #05020e, #000);
  background-size: 200% 200%;
  animation: gradient 10s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

div > * {
  outline: none !important;
}

.text-fill-transparent {
  -webkit-text-fill-color: transparent !important;
}
